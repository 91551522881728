/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./src/styles/responsive.css"
import "./src/styles/bootstrap.min.css"
import "./src/styles/global.css"
import "./src/styles/style.css"
